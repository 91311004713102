<!--eslint-disable-->

<template>
    <b-row class="mx-0 cursor-pointer" @click="$emit('edit')">
        <!--    Option: Address    -->
        <b-col
            sm="12"
            class="px-1 mt-1"
        >
            <my-option
                :title="$t('Title')"
                my-class="flex-column"
            />

            <b-row class="mb-50">
                <b-col sm="12">
                    <b-form-input
                        id="popover-footerlabl--0"
                        v-model.trim="item.title"
                        :placeholder="$t('Title')"
                        max-length="50"
                        disabled
                    />

                    <!--    Popover    -->
                    <b-popover
                        target="popover-footerlabl--0"
                        triggers="focus"
                        variant="primary"
                    >
                        <span>{{ $t('MaximumLength') + ': 50' }}</span>
                    </b-popover>
                </b-col>
            </b-row>

            <my-option
                :title="$t('contact.address')"
            />
            <b-row>
                <b-col sm="12"
                       md="6"
                       lg="4"
                >
                    <b-form-input
                        id="popover-footerAddress1"
                        v-model.trim="item.footerAddress1"
                        :placeholder="$t('lineOne')"
                        disabled
                        max-length="50"
                    />

                    <!--    Popover    -->
                    <b-popover
                        target="popover-footerAddress1"
                        triggers="focus"
                        variant="primary"
                    >
                        <span>{{ $t('MaximumLength') + ': 50' }}</span>
                    </b-popover>
                </b-col>
                <b-col sm="12"
                       md="6"
                       lg="4"
                >
                    <b-form-input
                        id="popover-footerAddress2"
                        v-model.trim="item.footerAddress2"
                        :placeholder="$t('lineTwo')"
                        disabled
                        max-length="50"
                    />

                    <!--    Popover    -->
                    <b-popover
                        target="popover-footerAddress2"
                        triggers="focus"
                        variant="primary"
                    >
                        <span>{{ $t('MaximumLength') + ': 50' }}</span>
                    </b-popover>
                </b-col>
                <b-col sm="12"
                       md="6"
                       lg="4"
                >
                    <b-form-input
                        id="popover-footerAddress3"
                        v-model.trim="item.footerAddress3"
                        :placeholder="$t('lineThree')"
                        disabled
                        max-length="50"
                    />

                    <!--    Popover    -->
                    <b-popover
                        target="popover-footerAddress3"
                        triggers="focus"
                        variant="primary"
                    >
                        <span>{{ $t('MaximumLength') + ': 50' }}</span>
                    </b-popover>
                </b-col>
            </b-row>
        </b-col>

        <!--    Option: Links    -->
        <b-col
            sm="12"
            class="px-1 mt-1"
        >
            <my-option
                :title="$t('Links')"
                my-class="flex-column"
            />

            <b-row class="flex-grow-1 w-100">
                <b-col
                    md="4"
                >
                    <b-form-input
                        id="popover-footerName-0"
                        v-model.trim="item.footerLinks[0].name"
                        :placeholder="$t('Name')"
                        disabled
                        max-length="50"
                    />

                    <!--    Popover    -->
                    <b-popover
                        target="popover-footerName-0"
                        triggers="focus"
                        variant="primary"
                    >
                        <span>{{ $t('MaximumLength') + ': 50' }}</span>
                    </b-popover>
                </b-col>

                <b-col
                    md="8"
                >
                    <b-form-input
                        v-model.trim="item.footerLinks[0].link"
                        :placeholder="$t('Link')"
                        disabled
                    />
                </b-col>
            </b-row>

            <hr class="w-100">

            <b-row class="flex-grow-1 w-100">
                <b-col
                    md="4"
                >
                    <b-form-input
                        id="popover-footerName-1"
                        v-model.trim="item.footerLinks[1].name"
                        :placeholder="$t('Name')"
                        disabled
                        max-length="50"
                    />

                    <!--    Popover    -->
                    <b-popover
                        target="popover-footerName-1"
                        triggers="focus"
                        variant="primary"
                    >
                        <span>{{ $t('MaximumLength') + ': 50' }}</span>
                    </b-popover>
                </b-col>

                <b-col
                    md="8"
                >
                    <b-form-input
                        v-model.trim="item.footerLinks[1].link"
                        :placeholder="$t('Link')"
                        disabled
                    />
                </b-col>
            </b-row>

            <hr class="w-100">

            <b-row class="flex-grow-1 w-100">
                <b-col
                    md="4"
                >
                    <b-form-input
                        id="popover-footerName-2"
                        v-model.trim="item.footerLinks[2].name"
                        :placeholder="$t('Name')"
                        disabled
                        max-length="50"
                    />

                    <!--    Popover    -->
                    <b-popover
                        target="popover-footerName-2"
                        triggers="focus"
                        variant="primary"
                    >
                        <span>{{ $t('MaximumLength') + ': 50' }}</span>
                    </b-popover>
                </b-col>

                <b-col
                    md="8"
                >
                    <b-form-input
                        v-model.trim="item.footerLinks[2].link"
                        :placeholder="$t('Link')"
                        disabled
                    />
                </b-col>
            </b-row>
        </b-col>

        <!--    Option: Description    -->
        <b-col
            sm="12"
            class="px-1 mt-1"
        >
            <my-option
                :title="$t('Description')"
            />
            <div class="d-flex align-content-end align-items-end">
                <b-form-textarea
                    id="popover-footerDesc"
                    v-model.trim="item.footerDescription"
                    :placeholder="$t('Description')"
                    max-length="170"
                    disabled
                />

                <b-button
                    variant="outline-danger"
                    class="btn-icon ml-1 offerWebsiteAdd"
                    @click="$emit('delete') "
                >
                    <feather-icon icon="MinusIcon" />
                </b-button>

                <!--    Popover    -->
                <b-popover
                    target="popover-footerDesc"
                    triggers="focus"
                    variant="primary"
                    placement="top"
                >
                    <span>{{ $t('MaximumLength') + ': 170' }}</span>
                </b-popover>
            </div>
        </b-col>

    </b-row>
</template>

<script>
import { BFormTextarea, BPopover } from 'bootstrap-vue'
import myOption from '@/views/offers/website/Option.vue'

export default {
  components: {
    myOption,
    BPopover,
    BFormTextarea,
  },
  props: ['item', 'defImg', 'translationNr'],
  emits: ['delete', 'edit'],
  data: () => ({
    id: `${Math.random().toString(36).substr(2, 9)}`,
  }),
  methods: {
  },
}
</script>
