<template>
  <b-form>
    <!--    Offer Info    -->
    <div class="mb-1 d-flex flex-wrap align-items-center">
      <!--    Offer Info: preview    -->
      <b-button
        v-if="oldId"
        v-b-tooltip.hover
        :disabled="loading"
        variant="flat-warning"
        :title="$t('Preview')"
        class="btn-icon px-25 py-25"
        :href="`${location()}/offer/preview/${oldId}`"
        target="_blank"
      >
        <feather-icon
          icon="EyeIcon"
        />
      </b-button>

      <!--    Offer Info: is active    -->
      <b-button
        v-b-tooltip.hover
        :disabled="loading"
        :variant="newWebsite.active ? 'flat-success': 'flat-danger'"
        :title="$t(newWebsite.active ? 'Deactivate' : 'Activate')"
        class="btn-icon px-25 py-25"
        @click="newWebsite.active = !newWebsite.active"
      >
        <feather-icon
          :icon="newWebsite.active ? 'CheckIcon' : 'XIcon'"
        />
      </b-button>

      <!--    Offer Info: simple text    -->
      <h2 class="mx-25 my-0">
        {{ $t('offer.website.This') }}:
      </h2>

      <!--    Offer Info: title    -->
      <strong class="h2 my-0 ml-25 font-weight-bolder text-primary">
        {{ newWebsite.webName }}
      </strong>
    </div>

    <!--    Buttons    -->
    <div
      class="mb-25 d-flex flex-wrap"
      style="gap: .34rem"
    >
      <!--    Button: Cancel    -->
      <b-button
        variant="warning"
        size="sm"
        @click="$router.go(-1)"
      >
        <feather-icon icon="ArrowLeftIcon" />

        {{ $t('Return') }}
      </b-button>

      <!--    Button: Save    -->
      <b-button
        :disabled="loading"
        variant="primary"
        size="sm"
        @click="pushToServer()"
      >
        {{ $t('Save') }}
      </b-button>
    </div>

    <!--    Sections    -->
    <validation-observer tag="form">
      <b-row v-if="!loading">
        <b-col>
          <b-form-input
            v-model.trim="newWebsite.webName"
            :placeholder="$t('Title')"
            class="mt-1"
          />

          <app-collapse class="px-0">
            <!--    Section: Identity    -->
            <my-section
              :title="$t('offer.website.Identity')"
              :alert="$t('offer.website.info.Identity')"
              :is-disabled="section.identity"
              :disable="false"
            >
              <b-row class="mx-0">
                <!--    Option: Title    -->
                <b-col
                  sm="12"
                  md="6"
                  lg="4"
                  class="px-1"
                >
                  <my-option
                    :title="$t('Title')"
                    :tip="$t('offer.website.tips.Title')"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('Title')"
                      rules="required"
                      class="w-100"
                    >
                      <b-form-group>
                        <b-form-input
                          id="popover-title"
                          v-model.trim="newWebsite.name"
                          :placeholder="$t('Title')"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>

                        <!--    Popover    -->
                        <b-popover
                          target="popover-title"
                          triggers="focus"
                          variant="primary"
                        >
                          <span>{{ $t('offer.website.tips.Title') }}</span>
                        </b-popover>
                      </b-form-group>
                    </validation-provider>
                  </my-option>
                </b-col>

                <!--    Option: Logo    -->
                <b-col
                  sm="12"
                  md="6"
                  lg="4"
                  class="px-1"
                >
                  <my-option
                    :title="'Logo'"
                    :tip="`${$t('PreferredFile')}: (150x300)px`"
                    :my-class="'dragDrop'"
                  >
                    <div>
                      <b-img
                        id="img-logo"
                        class="px-0 py-0 center"
                        thumbnail
                        fluid
                        :src="!!newWebsite.logo && newWebsite.logo.preview ? `data:image/webp;base64,${newWebsite.logo.preview}`: require('@/assets/icons/user/userBackground.svg')"
                      />

                      <b-button
                        v-b-tooltip.hover.v-primary
                        variant="primary"
                        :title="$t('DragDropFile')"
                      >
                        <feather-icon icon="PlusIcon" />
                        <input
                          type="file"
                          @change="onChange($event, 'logo', 2000)"
                        >
                      </b-button>
                    </div>
                  </my-option>
                </b-col>

                <!--    Option: Favicon    -->
                <b-col
                  sm="12"
                  md="6"
                  lg="4"
                  class="mt-1 mt-md-0"
                >
                  <my-option
                    :title="'Favicon'"
                    :tip="`${$t('PreferredFile')}: (150x150)px`"
                    :my-class="'dragDrop rec'"
                  >
                    <div>
                      <b-img
                        id="img-favicon"
                        class="px-0 py-0 center"
                        thumbnail
                        fluid

                        :src="!!newWebsite.favicon && newWebsite.favicon.preview ? `data:image/webp;base64,${newWebsite.favicon.preview}`: require('@/assets/icons/user/user.svg')"
                      />

                      <b-button
                        v-b-tooltip.hover.v-primary
                        variant="primary"
                        :title="$t('DragDropFile')"
                      >
                        <feather-icon icon="PlusIcon" />
                        <input
                          type="file"
                          @change="onChange($event, 'favicon', 2000)"
                        >
                      </b-button>
                    </div>
                  </my-option>
                </b-col>
              </b-row>
            </my-section>

            <!--    Section: Client Data    -->
            <my-section
              :title="$t('offer.website.ClientData')"
              :is-disabled="section.identity"
              :disable="false"
            >
              <b-form-checkbox
                v-model="newWebsite.isClientData"
                checked="true"
                name="check-button"
                switch
                inline
                class="w-100"
              >
                {{ $t('offer.website.checkBoxs.clientData') }}
              </b-form-checkbox>
            </my-section>

            <!--    Section: Palette Colours    -->
            <my-section
              :title="$t('offer.website.PaletteColours')"
              :is-disabled="section.paletteColours"
              :disable="false"
            >
              <b-row
                class="mx-0"
                style="row-gap: 1.5rem"
              >
                <!--    Option: Color Main    -->
                <b-col
                  sm="12"
                  md="6"
                  lg="3"
                >
                  <my-option
                    :title="$t('offer.website.colors.Main')"
                    :my-class="'colorPicker'"
                  >
                    <input
                      type="color"
                      style="border-radius: .5rem; overflow: hidden;"
                      :value="newWebsite.colorMenu"
                      @change="newWebsite.colorMenu = $event.target.value"
                    >
                  </my-option>
                </b-col>

                <!--    Option: Color Footer    -->
                <b-col
                  sm="12"
                  md="6"
                  lg="3"
                >
                  <my-option
                    :title="$t('offer.website.colors.Footer')"
                    :my-class="'colorPicker'"
                  >
                    <input
                      type="color"
                      style="border-radius: .5rem; overflow: hidden;"
                      :value="newWebsite.colorFooter"
                      @change="newWebsite.colorFooter = $event.target.value"
                    >
                  </my-option>
                </b-col>

                <!--    Option: Color Text Footer    -->
                <b-col
                  sm="12"
                  md="6"
                  lg="3"
                >
                  <my-option
                    :title="$t('offer.website.colors.FooterText')"
                    :my-class="'colorPicker'"
                  >
                    <input
                      type="color"
                      style="border-radius: .5rem; overflow: hidden;"
                      :value="newWebsite.colorTextFooter"
                      @change="newWebsite.colorTextFooter = $event.target.value"
                    >
                  </my-option>
                </b-col>

                <!--    Option: Color Link Footer    -->
                <b-col
                  sm="12"
                  md="6"
                  lg="3"
                >
                  <my-option
                    :title="$t('offer.website.colors.FooterLinkText')"
                    :my-class="'colorPicker'"
                  >
                    <input
                      type="color"
                      style="border-radius: .5rem; overflow: hidden;"
                      :value="newWebsite.colorLinksFooter"
                      @change="newWebsite.colorLinksFooter = $event.target.value"
                    >
                  </my-option>
                </b-col>

                <!--    Option: Color Main Banner    -->
                <b-col
                  sm="12"
                  md="6"
                  lg="3"
                >
                  <my-option
                    :title="$t('offer.website.colors.MainBanner')"
                    :my-class="'colorPicker'"
                  >
                    <input
                      type="color"
                      style="border-radius: .5rem; overflow: hidden;"
                      :value="newWebsite.colorBanner"
                      @change="newWebsite.colorBanner = $event.target.value"
                    >
                  </my-option>
                </b-col>

                <!--    Option: Color Buttons    -->
                <b-col
                  sm="12"
                  md="6"
                  lg="3"
                >
                  <my-option
                    :title="$t('offer.website.colors.Buttons')"
                    :my-class="'colorPicker'"
                  >
                    <input
                      type="color"
                      style="border-radius: .5rem; overflow: hidden;"
                      :value="newWebsite.colorButtons"
                      @change="newWebsite.colorButtons = $event.target.value"
                    >
                  </my-option>
                </b-col>
              </b-row>

              <b-row
                class="mx-0 mt-3"
                style="row-gap: 1.5rem"
              >
                <!--    Option: Color Main Banner Text    -->
                <b-col
                  sm="12"
                  md="6"
                  lg="3"
                >
                  <my-option
                    :title="$t('offer.website.colors.MainBannerText')"
                    :my-class="'colorPicker'"
                  >
                    <input
                      type="color"
                      style="border-radius: .5rem; overflow: hidden;"
                      :value="newWebsite.colorBannerText"
                      @change="newWebsite.colorBannerText = $event.target.value"
                    >
                  </my-option>
                </b-col>

                <!--    Option: Color QAndA Selected    -->
                <b-col
                  sm="12"
                  md="6"
                  lg="3"
                >
                  <my-option
                    :title="$t('offer.website.colors.QAndASelected')"
                    :my-class="'colorPicker'"
                  >
                    <input
                      type="color"
                      style="border-radius: .5rem; overflow: hidden;"
                      :value="newWebsite.colorQAndASelected"
                      @change="newWebsite.colorQAndASelected = $event.target.value"
                    >
                  </my-option>
                </b-col>

                <!--    Option: Color QAndA Selected Text    -->
                <b-col
                  sm="12"
                  md="6"
                  lg="3"
                >
                  <my-option
                    :title="$t('offer.website.colors.QAndASelectedText')"
                    :my-class="'colorPicker'"
                  >
                    <input
                      type="color"
                      style="border-radius: .5rem; overflow: hidden;"
                      :value="newWebsite.colorQAndASelectedText"
                      @change="newWebsite.colorQAndASelectedText = $event.target.value"
                    >
                  </my-option>
                </b-col>

                <!--    Option: Color Contact Text    -->
                <b-col
                  sm="12"
                  md="6"
                  lg="3"
                >
                  <my-option
                    :title="$t('offer.website.colors.ContactText')"
                    :my-class="'colorPicker'"
                  >
                    <input
                      type="color"
                      style="border-radius: .5rem; overflow: hidden;"
                      :value="newWebsite.colorContactText"
                      @change="newWebsite.colorContactText = $event.target.value"
                    >
                  </my-option>
                </b-col>
              </b-row>

              <b-row
                class="mx-0 mt-3"
                style="row-gap: 1.5rem"
              >
                <!--    Option: Color Video Text    -->
                <b-col
                  sm="12"
                  md="6"
                  lg="3"
                >
                  <my-option
                    :title="$t('offer.website.colors.VideoText')"
                    :my-class="'colorPicker'"
                  >
                    <input
                      type="color"
                      style="border-radius: .5rem; overflow: hidden;"
                      :value="newWebsite.colorVideoText"
                      @change="newWebsite.colorVideoText = $event.target.value"
                    >
                  </my-option>
                </b-col>
              </b-row>
            </my-section>

            <!--    Section: Fonts    -->
            <my-section
              :title="$t('offer.website.Fonts')"
              :is-disabled="section.paletteColours"
              :disable="false"
            >
              <b-row class="mx-0">
                <!--    Option: Font Main    -->
                <b-col
                  sm="12"
                  md="6"
                  lg="3"
                  class="px-1"
                >
                  <my-option
                    :title="$t('offer.website.fonts.Main')"
                  >
                    <sw-select>
                      <v-select
                        v-model="newWebsite.fontFamily"
                        :options="fontFamilyList"
                        class="w-100"
                        :placeholder="$t('offer.website.fonts.Main')"
                      />
                    </sw-select>
                  </my-option>
                </b-col>
              </b-row>
            </my-section>

            <!--    Section: Banner    -->
            <my-section
              :title="$t('offer.website.Banner')"
              :alert="$t('offer.website.info.Banner')"
              :is-disabled="section.banner"
              :disable="false"
            >
              <!--    Picture    -->
              <b-row
                class="mx-0"
                style="row-gap: 1rem"
              >
                <b-col
                  sm="6"
                  md="4"
                  lg="3"
                >
                  <sw-form-input-image
                    v-model="newWebsite.bannerBackground"
                    :label="$t('Styles.Background')"
                    :info="$tc('file.Max', 1, { size: 2000 })"
                    fluid-x
                    height="140px"
                    switch-enabled
                    max-size="2000"
                    :enabled="newWebsite.bannerBackgroundVisible"
                    @input="e => onChange(e.fr, 'bannerBackground', 2000)"
                    @change-enabled="newWebsite.bannerBackgroundVisible = $event; $forceUpdate()"
                  />
                </b-col>

                <b-col
                  sm="6"
                  md="4"
                  lg="3"
                >
                  <sw-form-input-image
                    v-model="newWebsite.bannerLogo"
                    :label="$t('offer.website.SelectBannerPicture')"
                    :info="$tc('file.Max', 1, { size: 2000 })"
                    fluid-x
                    height="140px"
                    max-size="2000"
                    switch-enabled
                    :enabled="newWebsite.bannerVisible"
                    @input="e => onChange(e.fr, 'bannerLogo', 2000)"
                    @change-enabled="newWebsite.bannerVisible = $event; $forceUpdate()"
                  />
                </b-col>
              </b-row>

              <!--    Option: Title    -->
              <b-row class="mx-0 mt-1">
                <b-col
                  sm="12"
                  md="6"
                  lg="8"
                >
                  <my-option
                    :title="$t('Title')"
                    :tip="$t('MaximumLength') + ': 40'"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('Title')"
                      rules="required"
                      class="w-100"
                    >
                      <b-form-group>
                        <b-form-input
                          id="popover-bannerTitle"
                          v-model.trim="newWebsite.bannerTitle"
                          :placeholder="$t('Title')"
                          :state="errors.length > 0 ? false:null"
                          maxlength="40"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>

                        <!--    Popover    -->
                        <b-popover
                          target="popover-bannerTitle"
                          triggers="focus"
                          variant="primary"
                        >
                          <span>{{ $t('MaximumLength') + ': 40' }}</span>
                        </b-popover>
                      </b-form-group>
                    </validation-provider>
                  </my-option>
                </b-col>
              </b-row>

              <!--    Option: Description    -->
              <b-row class="mx-0">
                <b-col
                  sm="12"
                  md="6"
                  lg="8"
                >
                  <my-option
                    :title="$t('task.Description')"
                    :tip="$t('MaximumLength') + ': 110'"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('Title')"
                      rules="required"
                      class="w-100"
                    >
                      <b-form-group>
                        <b-form-textarea
                          id="popover-bannerDesc"
                          v-model.trim="newWebsite.bannerDesc"
                          :placeholder="$t('Title')"
                          :state="errors.length > 0 ? false:null"
                          maxlength="110"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>

                        <!--    Popover    -->
                        <b-popover
                          target="popover-bannerDesc"
                          triggers="focus"
                          variant="primary"
                        >
                          <span>{{ $t('MaximumLength') + ': 110' }}</span>
                        </b-popover>
                      </b-form-group>
                    </validation-provider>
                  </my-option>
                </b-col>
              </b-row>
            </my-section>

            <!--    START::Draggable sections    -->
            <hr>

            <draggable
              v-model="sectionList"
              tag="app-collapse"
              class="cursor-move"
            >
              <div
                v-for="(item, index) in sectionList"
                :key="index"
              >
                <my-section
                  :is-draggable="true"
                  :title="$t(`offer.website.${item.title}`)"
                  :alert="$t(`offer.website.info.${item.title}`)"
                  :disable="Boolean(item.disabledKey)"
                  :is-disabled="newWebsite[item.disabledKey]"
                  @is-disable="val => newWebsite[item.disabledKey] = val"
                >
                  <component
                    :is="item.component"
                    v-model="newWebsite"
                  />
                </my-section>
              </div>
            </draggable>

            <hr>
            <!--    END::Draggable sections    -->

            <!--    Section: Files    -->
            <my-section
              :title="$t('OfferFiles')"
              :disable="false"
            >
              <b-col>
                <my-option :title="$t('Name')">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Name')"
                    class="w-100"
                  >
                    <b-form-group>
                      <b-form-input
                        id="popover-fileName"
                        v-model.trim="newWebsite.filesBannerText"
                        :placeholder="$t('Name')"
                        :state="errors.length > 0 ? false:null"
                        maxlength="50"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>

                      <!--    Popover    -->
                      <b-popover
                        target="popover-fileName"
                        triggers="focus"
                        variant="primary"
                      >
                        <span>{{ $t('MaximumLength') + ': 50' }}</span>
                      </b-popover>
                    </b-form-group>
                  </validation-provider>
                </my-option>

                <!--    START::Allow PDF    -->
                <b-form-checkbox
                  v-if="false"
                  v-model="newWebsite.filesAllowPDF"
                  checked="true"
                  name="check-button"
                  switch
                  inline
                  class="w-100"
                >
                  {{ $t('AllowGeneratingOfferPagesAsAPDFFile') }}
                </b-form-checkbox>
                <!--    END::Allow PDF    -->
              </b-col>
            </my-section>

            <!--    Section: Footer    -->
            <my-section
              :title="$t('offer.website.Footer')"
              :is-disabled="newWebsite.footerEnabled"
              @is-disable="val => newWebsite.footerEnabled = val"
            >
              <my-option
                :title="$t('Options')"
                class="mb-50"
              >
                <b-form-checkbox
                  v-model="newWebsite.footerShowAddress"
                  checked="true"
                  name="check-button"
                  switch
                  inline
                  class="w-100"
                >
                  {{ $t('offer.website.checkBoxs.isAddres') }}
                </b-form-checkbox>
              </my-option>
              <my-option title="Copyright" />
              <b-form-input
                id="popover-footerCopyright"
                v-model.trim="newWebsite.footerCopyright"
                placeholder="Copyright"
                max-length="50"
              />
              <!--    Popover    -->
              <b-popover
                target="popover-footerCopyright"
                triggers="focus"
                variant="primary"
                placement="top"
              >
                <span>{{ $t('MaximumLength') + ': 50' }}</span>
              </b-popover>
              <Footer v-model="newWebsite"
                      class="mt-50"
                      @update-footer="$set(newWebsite.offerWebTemplateFooterItems, $event.index, $event.value)"
              />
            </my-section>
            <!--              <b-row class="mx-0">-->
            <!--                &lt;!&ndash;    Option: Options    &ndash;&gt;-->
            <!--                <b-col-->
            <!--                  sm="12"-->
            <!--                  class="px-1"-->
            <!--                >-->

            <!--                </b-col>-->

            <!--                &lt;!&ndash;    Option: Address    &ndash;&gt;-->
            <!--                <b-col-->
            <!--                  sm="12"-->
            <!--                  class="px-1 mt-1"-->
            <!--                >-->
            <!--                  <my-option-->
            <!--                    :title="$t('contact.address')"-->
            <!--                  />-->
            <!--                  <b-row>-->
            <!--                    <b-col sm="12"-->
            <!--                           md="6"-->
            <!--                           lg="4"-->
            <!--                    >-->
            <!--                      <b-form-input-->
            <!--                        id="popover-footerAddress1"-->
            <!--                        v-model.trim="newWebsite.footerAddress1"-->
            <!--                        :placeholder="$t('lineOne')"-->
            <!--                        :disabled="!newWebsite.footerShowAddress"-->
            <!--                        max-length="50"-->
            <!--                      />-->

            <!--                      &lt;!&ndash;    Popover    &ndash;&gt;-->
            <!--                      <b-popover-->
            <!--                        target="popover-footerAddress1"-->
            <!--                        triggers="focus"-->
            <!--                        variant="primary"-->
            <!--                      >-->
            <!--                        <span>{{ $t('MaximumLength') + ': 50' }}</span>-->
            <!--                      </b-popover>-->
            <!--                    </b-col>-->
            <!--                    <b-col sm="12"-->
            <!--                           md="6"-->
            <!--                           lg="4"-->
            <!--                    >-->
            <!--                      <b-form-input-->
            <!--                        id="popover-footerAddress2"-->
            <!--                        v-model.trim="newWebsite.footerAddress2"-->
            <!--                        :placeholder="$t('lineTwo')"-->
            <!--                        :disabled="!newWebsite.footerShowAddress"-->
            <!--                        max-length="50"-->
            <!--                      />-->

            <!--                      &lt;!&ndash;    Popover    &ndash;&gt;-->
            <!--                      <b-popover-->
            <!--                        target="popover-footerAddress2"-->
            <!--                        triggers="focus"-->
            <!--                        variant="primary"-->
            <!--                      >-->
            <!--                        <span>{{ $t('MaximumLength') + ': 50' }}</span>-->
            <!--                      </b-popover>-->
            <!--                    </b-col>-->
            <!--                    <b-col sm="12"-->
            <!--                           md="6"-->
            <!--                           lg="4"-->
            <!--                    >-->
            <!--                      <b-form-input-->
            <!--                        id="popover-footerAddress3"-->
            <!--                        v-model.trim="newWebsite.footerAddress3"-->
            <!--                        :placeholder="$t('lineThree')"-->
            <!--                        :disabled="!newWebsite.footerShowAddress"-->
            <!--                        max-length="50"-->
            <!--                      />-->

            <!--                      &lt;!&ndash;    Popover    &ndash;&gt;-->
            <!--                      <b-popover-->
            <!--                        target="popover-footerAddress3"-->
            <!--                        triggers="focus"-->
            <!--                        variant="primary"-->
            <!--                      >-->
            <!--                        <span>{{ $t('MaximumLength') + ': 50' }}</span>-->
            <!--                      </b-popover>-->
            <!--                    </b-col>-->
            <!--                  </b-row>-->
            <!--                </b-col>-->

            <!--                &lt;!&ndash;    Option: Links    &ndash;&gt;-->
            <!--                <b-col-->
            <!--                  sm="12"-->
            <!--                  class="px-1 mt-1"-->
            <!--                >-->
            <!--                  <my-option-->
            <!--                    :title="$t('Links')"-->
            <!--                    my-class="flex-column"-->
            <!--                  />-->

            <!--                  <b-row class="flex-grow-1 w-100">-->
            <!--                    <b-col-->
            <!--                      md="4"-->
            <!--                    >-->
            <!--                      <b-form-input-->
            <!--                        id="popover-footerName-0"-->
            <!--                        v-model.trim="newWebsite.footerLinks[0].name"-->
            <!--                        :placeholder="$t('Name')"-->
            <!--                        max-length="50"-->
            <!--                      />-->

            <!--                      &lt;!&ndash;    Popover    &ndash;&gt;-->
            <!--                      <b-popover-->
            <!--                        target="popover-footerName-0"-->
            <!--                        triggers="focus"-->
            <!--                        variant="primary"-->
            <!--                      >-->
            <!--                        <span>{{ $t('MaximumLength') + ': 50' }}</span>-->
            <!--                      </b-popover>-->
            <!--                    </b-col>-->

            <!--                    <b-col-->
            <!--                      md="8"-->
            <!--                    >-->
            <!--                      <b-form-input-->
            <!--                        v-model.trim="newWebsite.footerLinks[0].link"-->
            <!--                        :placeholder="$t('Link')"-->
            <!--                      />-->
            <!--                    </b-col>-->
            <!--                  </b-row>-->

            <!--                  <hr class="w-100">-->

            <!--                  <b-row class="flex-grow-1 w-100">-->
            <!--                    <b-col-->
            <!--                      md="4"-->
            <!--                    >-->
            <!--                      <b-form-input-->
            <!--                        id="popover-footerName-1"-->
            <!--                        v-model.trim="newWebsite.footerLinks[1].name"-->
            <!--                        :placeholder="$t('Name')"-->
            <!--                        max-length="50"-->
            <!--                      />-->

            <!--                      &lt;!&ndash;    Popover    &ndash;&gt;-->
            <!--                      <b-popover-->
            <!--                        target="popover-footerName-1"-->
            <!--                        triggers="focus"-->
            <!--                        variant="primary"-->
            <!--                      >-->
            <!--                        <span>{{ $t('MaximumLength') + ': 50' }}</span>-->
            <!--                      </b-popover>-->
            <!--                    </b-col>-->

            <!--                    <b-col-->
            <!--                      md="8"-->
            <!--                    >-->
            <!--                      <b-form-input-->
            <!--                        v-model.trim="newWebsite.footerLinks[1].link"-->
            <!--                        :placeholder="$t('Link')"-->
            <!--                      />-->
            <!--                    </b-col>-->
            <!--                  </b-row>-->

            <!--                  <hr class="w-100">-->

            <!--                  <b-row class="flex-grow-1 w-100">-->
            <!--                    <b-col-->
            <!--                      md="4"-->
            <!--                    >-->
            <!--                      <b-form-input-->
            <!--                        id="popover-footerName-2"-->
            <!--                        v-model.trim="newWebsite.footerLinks[2].name"-->
            <!--                        :placeholder="$t('Name')"-->
            <!--                        max-length="50"-->
            <!--                      />-->

            <!--                      &lt;!&ndash;    Popover    &ndash;&gt;-->
            <!--                      <b-popover-->
            <!--                        target="popover-footerName-2"-->
            <!--                        triggers="focus"-->
            <!--                        variant="primary"-->
            <!--                      >-->
            <!--                        <span>{{ $t('MaximumLength') + ': 50' }}</span>-->
            <!--                      </b-popover>-->
            <!--                    </b-col>-->

            <!--                    <b-col-->
            <!--                      md="8"-->
            <!--                    >-->
            <!--                      <b-form-input-->
            <!--                        v-model.trim="newWebsite.footerLinks[2].link"-->
            <!--                        :placeholder="$t('Link')"-->
            <!--                      />-->
            <!--                    </b-col>-->
            <!--                  </b-row>-->
            <!--                </b-col>-->

            <!--                &lt;!&ndash;    Option: Description    &ndash;&gt;-->
            <!--                <b-col-->
            <!--                  sm="12"-->
            <!--                  class="px-1 mt-1"-->
            <!--                >-->
            <!--                  <my-option-->
            <!--                    :title="$t('Description')"-->
            <!--                  >-->
            <!--                    <b-form-textarea-->
            <!--                      id="popover-footerDesc"-->
            <!--                      v-model.trim="newWebsite.footerDescription"-->
            <!--                      :placeholder="$t('Description')"-->
            <!--                      max-length="170"-->
            <!--                    />-->

            <!--                    &lt;!&ndash;    Popover    &ndash;&gt;-->
            <!--                    <b-popover-->
            <!--                      target="popover-footerDesc"-->
            <!--                      triggers="focus"-->
            <!--                      variant="primary"-->
            <!--                      placement="top"-->
            <!--                    >-->
            <!--                      <span>{{ $t('MaximumLength') + ': 170' }}</span>-->
            <!--                    </b-popover>-->
            <!--                  </my-option>-->
            <!--                </b-col>-->

            <!--                &lt;!&ndash;    Option: Copyright    &ndash;&gt;-->
            <!--                <b-col-->
            <!--                  sm="12"-->
            <!--                  class="px-1 mt-1"-->
            <!--                >-->
            <!--                  <my-option-->
            <!--                    title="Copyright"-->
            <!--                  >-->
            <!--                    <b-form-input-->
            <!--                      id="popover-footerCopyright"-->
            <!--                      v-model.trim="newWebsite.footerCopyright"-->
            <!--                      placeholder="Copyright"-->
            <!--                      max-length="50"-->
            <!--                    />-->

            <!--                    &lt;!&ndash;    Popover    &ndash;&gt;-->
            <!--                    <b-popover-->
            <!--                      target="popover-footerCopyright"-->
            <!--                      triggers="focus"-->
            <!--                      variant="primary"-->
            <!--                      placement="top"-->
            <!--                    >-->
            <!--                      <span>{{ $t('MaximumLength') + ': 50' }}</span>-->
            <!--                    </b-popover>-->
            <!--                  </my-option>-->
            <!--                </b-col>-->
            <!--              </b-row>-->
            <!--            </my-section>-->
          </app-collapse>
        </b-col>
      </b-row>
      <div v-else>
        <div class="basicOfferInfoLoading d-flex w-100 justify-content-center align-items-center">
          <b-spinner
            class="align-middle"
            variant="primary"
          />
        </div>
      </div>
    </validation-observer>

    <!--    Button: Save    -->
    <b-button
      :disabled="loading"
      class="mb-1"
      variant="primary"
      @click="pushToServer()"
    >
      {{ $t('Save') }}
    </b-button>
  </b-form>
</template>

<script>
/* eslint-disable */
import { BAlert, BButton, BForm, BFormTextarea, BImg, BPopover, VBTooltip, } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@core/utils/validations/validations'
import { CREATE_CONTRACT, CREATE_WEBSITE, GET_WEBSITE, UPDATE_WEBSITE, UPLOAD_FILES, } from '@/@constants/mutations'
import myOption from '../../views/offers/website/Option.vue'
import mySection from '../../views/offers/website/Section.vue'
import opinion from '../../views/offers/website/Opinion.vue'
import draggable from 'vuedraggable'
import AboutCompany from './tabs/AboutCompany.vue'
import Projects from '@/pages/website/tabs/Projects.vue'
import QAndA from '@/pages/website/tabs/QAndA.vue'
import Video from '@/pages/website/tabs/Video.vue'
import CustomersOpinion from '@/pages/website/tabs/CustomersOpinion.vue'
import Portfolio from '@/pages/website/tabs/Portfolio.vue'
import Cooperation from '@/pages/website/tabs/Cooperation.vue'
import Contact from '@/pages/website/tabs/Contact.vue'
import InformationGrid from '@/pages/website/tabs/InformationGrid.vue'
import Footer from '@/pages/website/tabs/Footer.vue'

export default {
  components: {
      Footer,
    BAlert,
    BImg,
    BButton,
    BForm,
    BFormTextarea,
    BPopover,
    draggable,
    AppCollapse,
    vSelect,
    ValidationObserver,
    ValidationProvider,
    myOption,
    mySection,
    opinion,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data: vm => ({
    required,

    loading: true,
    oldId: null,

    sectionList: [
      {
        title: 'AboutCompany',
        disabledKey: 'aboutEnabled',
        component: AboutCompany,
        key: 'ABOUT_COMPANY',
      },
      {
        title: 'InformationGrid',
        disabledKey: 'informationGridEnabled',
        component: InformationGrid,
        key: 'INFORMATION_GRID',
      },
      {
        title: 'Products',
        component: Projects,
        key: 'PRODUCTS',
      },
      {
        title: 'QAndA',
        disabledKey: 'isQAndA',
        component: QAndA,
        key: 'QUESTION_AND_ANSWER',
      },
      {
        title: 'Video',
        disabledKey: 'isVideo',
        component: Video,
        key: 'VIDEO',
      },
      {
        title: 'CustomersOpinion',
        disabledKey: 'isOpinion',
        component: CustomersOpinion,
        key: 'OPINIONS',
      },
      {
        title: 'Portfolio',
        disabledKey: 'portfolioEnabled',
        component: Portfolio,
        key: 'PORTFOLIO',
      },
      {
        title: 'WeCooperatedWith',
        disabledKey: 'cooperationEnabled',
        component: Cooperation,
        key: 'COOPERATION',
      },
    ],

    newWebsite: {
      id: null,
      fontFamily: 'Arial',
      filesBannerText: vm.$i18n.t('OfferFiles'),
      active: null,
      webName: '',
      name: '',
      filesAllowPDF: false,
      logo: null,
      favicon: null,
      colorMenu: '',
      colorFooter: '',
      colorTextFooter: '',
      colorLinksFooter: '',
      colorBanner: '',
      colorButtons: '',
      colorBannerText: '',
      colorQAndASelected: '',
      colorQAndASelectedText: '',
      colorContactText: '',
      colorVideoText: '',
      cooperationName: '',
      offerWebTemplatePortfolioItems: [],
      cooperationBackgroundColor: '',
      cooperationBackgroundVisible: false,
      cooperationFiles: [],
      bannerTitle: '',
      bannerDesc: '',
      bannerBackground: null,
      bannerVisible: true,
      bannerBackgroundEnabled: false,
      bannerLogoEnabled: true,
      bannerLogo: null,
      aboutEnabled: false,
      informationGridEnabled: false,
      aboutTitle: '',
      aboutBanner: null,
      aboutDesc: '',
      aboutOptions: [],
      showProductsDescription: false,
      hideOfferPrice: false,
      productTitle: '',
      productDesc: '',
      productDetails: false,
      productPrices: false,
      isQAndA: false,
      QAndATitle: '',
      QAndADesc: '',
      QAndAOptions: [],
      videoTitle: '',
      isVideo: false,
      videoDesc: '',
      videoLink: '',
      isOpinion: false,
      opinionTitle: '',
      opinionDesc: '',
      opinionOptions: [],
      offerWebTemplateFooterItems: [],
      contactTitle: '',
      contactDesc: '',
      contactPhone: '',
      contactEmail: '',
      isClientData: false,
      contactAvatar: null,
      contactIsDetails: false,
      isAttachments: false,
      attachmentsPDF: false,
      attachmentsDOC: false,

      portfolioEnabled: false,
      cooperationEnabled: false,
      footerCopyright: '',

      footerEnabled: false,

      showInformationGridBox: false,
      informationGridTitle: '',
      informationGridDescription: '',
      informationGridOptions: [],
    },

    section: {
      identity: true,
      paletteColours: true,
      banner: true,
      about: true,
      products: true,
      QAndA: true,
      video: true,
      customersOpinion: true,
      contact: true,
      attachments: true,
      footer: true,
    },

    fontFamilyDef: 'Arial',
    fontFamilyList: [
      'Rubik',
      'Gadugi',
      'Times New Roman',
      'Arial',
    ],

  }),
  mounted() {
    this.getFromServer()
  },
  methods: {
    location() {
      return window.location.port ? 'https://dev.saleswizardapp.com' : window.location.origin
    },

    onChange(event, option, maxSize) {
      if (event.target.files[0].size > maxSize * 1000) {
        this.showToast('danger', this.$i18n.tc('MoreThanMB', 1, {
          type: this.$i18n.t(`SystemMoreThan.${option}`),
          size: maxSize,
        }))
        return
      }

      const [first] = event.target.files

      if (typeof this.newWebsite[option] !== 'undefined') {
        this.newWebsite[option] = first
      } else {
        // eslint-disable-next-line default-case
        switch (option) {
          case 'newOption-avatar':
            this.newOption.avatar = first
            break
        }
      }

      document.querySelector(`#img-${option}`).src = URL.createObjectURL(first)

      // eslint-disable-next-line consistent-return
      return first
    },

    getFromServer() {
      if (this.$route.params)
        this.oldId = this.$route.params.websiteId

      // eslint-disable-next-line no-unused-expressions
      this.oldId ? this.getWebsite() : this.loading = false
    },

    pushToServer() {
      this.loading = true

      this.pushWebsite()
    },

    getWebsite() {
      this.$store.dispatch(`websites/${GET_WEBSITE}`, this.oldId)
        .then(res => {
          const web = res.data.item

          if (web.sectionsOrder?.length) {
            const orders = web.sectionsOrder || []
              this.sectionList.mapKeys('key').forEach(key => {
                  if (!orders.includes(key)) orders.push(key)
              })

            const sortedSections = orders.map(key => {
              const section = this.sectionList.find(e => e.key === key)
              if (!section) return null

              return section
            }).filter(Boolean)

            this.sectionList = sortedSections
          }

          this.newWebsite.id = web.id
          this.newWebsite.active = web.active

          this.newWebsite.webName = web.name

          /*      Section: Identity             */
          this.newWebsite.name = web.translations[0].pageTitle
          this.newWebsite.logo = web.logo
          this.newWebsite.favicon = web.favicon

          /*      Set Section: Client Data          */
          this.newWebsite.isClientData = web.contactDetails

          this.newWebsite.fontFamily = !!web.fontFamily && web.fontFamily.length > 0 ? web.fontFamily : 'Arial'
          this.newWebsite.filesBannerText = !!web.filesBannerText && web.filesBannerText.length > 0 ? web.filesBannerText : this.$i18n.t('OfferFiles')

          /*      Get Section: Palette Colours          */
          this.newWebsite.colorMenu = !!web.menuColor && web.menuColor.length > 0 ? web.menuColor : '#000000'
          this.newWebsite.colorFooter = !!web.footerColor && web.footerColor.length > 0 ? web.footerColor : '#000000'
          this.newWebsite.colorTextFooter = !!web.footerTextColor && web.footerTextColor.length > 0 ? web.footerTextColor : '#000000'
          this.newWebsite.colorLinksFooter = !!web.footerLinkTextColor && web.footerLinkTextColor.length > 0 ? web.footerLinkTextColor : '#000000'
          this.newWebsite.colorBanner = !!web.bannerColor && web.bannerColor.length > 0 ? web.bannerColor : '#000000'
          this.newWebsite.colorButtons = !!web.buttonColor && web.buttonColor.length > 0 ? web.buttonColor : '#000000'
          this.newWebsite.colorBannerText = !!web.bannerTextColor && web.bannerTextColor.length > 0 ? web.bannerTextColor : '#000000'
          this.newWebsite.colorQAndASelected = !!web.questionSelectedColor && web.questionSelectedColor.length > 0 ? web.questionSelectedColor : '#000000'
          this.newWebsite.colorQAndASelectedText = !!web.questionSelectedTextColor && web.questionSelectedTextColor.length > 0 ? web.questionSelectedTextColor : '#000000'
          this.newWebsite.colorContactText = !!web.contactTextColor && web.contactTextColor.length > 0 ? web.contactTextColor : '#000000'
          this.newWebsite.colorVideoText = !!web.videoTextColor && web.videoTextColor.length > 0 ? web.videoTextColor : '#000000'

          /*      Get Section: Banner      */
          this.newWebsite.bannerTitle = web.translations[0].bannerTitle
          this.newWebsite.bannerDesc = web.translations[0].bannerDescription
          this.newWebsite.bannerBackgroundVisible = web.bannerBackgroundVisible
          this.newWebsite.bannerVisible = web.bannerVisible
          this.newWebsite.bannerBackground = web.bannerBackground
          this.newWebsite.bannerLogo = web.banner

          /*      Get Section: About               */
          this.newWebsite.aboutEnabled = web.companyEnabled
          this.newWebsite.aboutBanner = web.companyBanner
          this.newWebsite.aboutTitle = web.translations[0].companyTitle
          this.newWebsite.aboutDesc = web.translations[0].companyDescription
          this.newWebsite.aboutOptions = web.translations[0].companyOptions

          /*      Get Section: Products                */
          this.newWebsite.showProductsDescription = web.showProductsDescription
          this.newWebsite.productTitle = web.translations[0].productTitle
          this.newWebsite.productDesc = web.translations[0].productDescription
          this.newWebsite.productDetails = web.productDetails
          this.newWebsite.productPrices = web.productPrices
          this.newWebsite.hideOfferPrice = web.hideOfferPrice

          /*      Get Section: QAndA             */
          this.newWebsite.isQAndA = web.faqEnabled
          this.newWebsite.QAndATitle = web.translations[0].faqTitle
          this.newWebsite.QAndADesc = web.translations[0].faqDescription
          this.newWebsite.QAndAOptions = web.translations[0].questionsAndAnswers

          /*      Get Section: Video                */
          this.newWebsite.isVideo = web.videoEnabled
          this.newWebsite.videoTitle = web.translations[0].videoTitle
          this.newWebsite.videoDesc = web.translations[0].videoDescription
          this.newWebsite.videoLink = web.translations[0].videoLink

          /*      Get Section: Opinion                */
          this.newWebsite.informationGridEnabled = web.informationGridEnabled || false
          this.newWebsite.showInformationGridBox = web.informationGridAsBoxes
          this.newWebsite.informationGridTitle = web.translations[0].informationGridTitle
          this.newWebsite.informationGridDescription = web.translations[0].informationGridDescription
          this.newWebsite.informationGridOptions = web.offerWebTemplateInformationGridItems

          /*      Get Section: Opinion                */
          this.newWebsite.isOpinion = web.opinionsEnabled
          this.newWebsite.opinionTitle = web.translations[0].opinionTitle
          this.newWebsite.opinionDesc = web.translations[0].opinionDescription
          this.newWebsite.opinionOptions = web.offerWebTemplateOpinionItems

          /*      Get Section: Portfolio                */
          this.newWebsite.portfolioEnabled = web.portfolioEnabled
          this.newWebsite.portfolioName = web.translations[0].portfolioName
          this.newWebsite.offerWebTemplatePortfolioItems = web.offerWebTemplatePortfolioItems

          /*      Get Section: Cooperation                */
          this.newWebsite.cooperationEnabled = web.cooperationEnabled
          this.newWebsite.cooperationName = web.translations[0].cooperationName
          this.newWebsite.cooperationBackgroundColor = web.cooperationBackgroundColor
          this.newWebsite.cooperationBackgroundVisible = web.cooperationBackgroundVisible
          this.newWebsite.cooperationFiles = web.cooperationFiles

          /*      Get Section: Contact    */
          this.newWebsite.contactTitle = web.translations[0].contactTitle
          this.newWebsite.contactDesc = web.translations[0].contactDescription
          this.newWebsite.contactPhone = web.contactPhone
          this.newWebsite.contactEmail = web.contactEmail
          this.newWebsite.contactAvatar = web.contactAvatar
          this.newWebsite.contactIsDetails = web.contactCompanyDetails
          this.newWebsite.offerWebTemplateFooterItems = web.offerWebTemplateFooterItems || []

          /*      Get Section: Attachments          */
          this.newWebsite.isAttachments = web.attachmentsEnabled
          this.newWebsite.attachmentsPDF = web.generatePdf
          this.newWebsite.attachmentsDOC = web.additionalFiles

          /*      Get Section: Footer          */
          this.newWebsite.footerEnabled = web.footerEnabled ? web.footerEnabled : false
          this.newWebsite.footerShowAddress = web.footerShowAddress ? web.footerShowAddress : false
          this.newWebsite.footerAddress1 = web.footerAddress1 ? web.footerAddress1 : ''
          this.newWebsite.footerAddress2 = web.footerAddress2 ? web.footerAddress2 : ''
          this.newWebsite.footerAddress3 = web.footerAddress3 ? web.footerAddress3 : ''

          this.newWebsite.footerLinks =
            Array.isArray(web.footerLinks)
            && web.footerLinks?.length
              ? web.footerLinks
              : [
                {
                  name: '',
                  link: '',
                }, {
                  name: '',
                  link: '',
                }, {
                  name: '',
                  link: '',
                }
              ]
          this.newWebsite.footerDescription = web.footerDescription ? web.footerDescription : ''
          this.newWebsite.footerCopyright = web.footerCopyright ? web.footerCopyright : ''

          this.loading = false
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },

    prepareData() {
      const newWebsite = {
        active: this.newWebsite.active,
        name: this.newWebsite.webName,

        /*      Set Section: Identity          */
        logo: this.newWebsite.logo,
        favicon: this.newWebsite.favicon,

        /*      Set Section: Client Data          */
        contactDetails: this.newWebsite.isClientData,

        /*      Set Section: Font      */
        fontFamily: this.newWebsite.fontFamily,
        /*      Set Section: Files Banner      */
        filesBannerText: this.newWebsite.filesBannerText,

        /*      Set Section: Palette Colours      */
        menuColor: this.newWebsite.colorMenu,
        footerColor: this.newWebsite.colorFooter,
        footerTextColor: this.newWebsite.colorTextFooter,
        footerLinkTextColor: this.newWebsite.colorLinksFooter,
        bannerColor: this.newWebsite.colorBanner,
        buttonColor: this.newWebsite.colorButtons,
        bannerTextColor: this.newWebsite.colorBannerText,
        questionSelectedColor: this.newWebsite.colorQAndASelected,
        questionSelectedTextColor: this.newWebsite.colorQAndASelectedText,
        contactTextColor: this.newWebsite.colorContactText,
        videoTextColor: this.newWebsite.colorVideoText,

        /*      Set Section: Banner              */
        bannerBackground: this.newWebsite.bannerBackground,
        banner: this.newWebsite.bannerLogo,
        bannerVisible: this.newWebsite.bannerVisible,
        bannerBackgroundVisible: this.newWebsite.bannerBackgroundVisible,

        /*      Get Section: About               */
        companyEnabled: this.newWebsite.aboutEnabled,
        companyBanner: this.newWebsite.aboutBanner,

        /*      Set Section: Products             */
        showProductsDescription: this.newWebsite.showProductsDescription,
        productDetails: this.newWebsite.productDetails,
        productPrices: this.newWebsite.productPrices,
        hideOfferPrice: this.newWebsite.hideOfferPrice,

        /*      Set Section: QAndA             */
        faqEnabled: this.newWebsite.isQAndA,

        /*      Set Section: Video                */
        videoEnabled: this.newWebsite.isVideo,

        /*      Set Section: Opinion                */
        opinionsEnabled: this.newWebsite.isOpinion,
        offerWebTemplateOpinionItems: this.newWebsite.opinionOptions,

        /*      Set Section: Portfolio                */
        portfolioEnabled: this.newWebsite.portfolioEnabled,
        offerWebTemplatePortfolioItems: this.newWebsite.offerWebTemplatePortfolioItems,

        /*      Set Section: Cooperation                */
        cooperationEnabled: this.newWebsite.cooperationEnabled,
        cooperationBackgroundVisible: this.newWebsite.cooperationBackgroundVisible,
        cooperationFiles: this.newWebsite.cooperationFiles,
        cooperationBackgroundColor: this.newWebsite.cooperationBackgroundColor?.hex ? this.newWebsite.cooperationBackgroundColor?.hex : this.newWebsite.cooperationBackgroundColor,

        /*      Set Section: Contact              */
        contactAvatar: this.newWebsite.contactAvatar,
        contactCompanyDetails: this.newWebsite.contactIsDetails,
        contactPhone: this.newWebsite.contactPhone,
        contactEmail: this.newWebsite.contactEmail,

        /*      Set Section: Attachments          */
        attachmentsEnabled: this.newWebsite.isAttachments,
        generatePdf: this.newWebsite.attachmentsPDF,
        additionalFiles: this.newWebsite.attachmentsDOC,

        /*      Set Section: Footer          */
        footerEnabled: this.newWebsite.footerEnabled,
        footerShowAddress: this.newWebsite.footerShowAddress,
        // footerAddress1: this.newWebsite.footerAddress1,
        // footerAddress2: this.newWebsite.footerAddress2,
        // footerAddress3: this.newWebsite.footerAddress3,
        // footerLinks: this.newWebsite.footerLinks,
        // footerDescription: this.newWebsite.footerDescription,
        footerCopyright: this.newWebsite.footerCopyright,

        translations: [
          {
            locale: 'pl_pl',
            /*      Set Section: Information Grid          */
            informationGridTitle: this.newWebsite.informationGridTitle,
            informationGridDescription: this.newWebsite.informationGridDescription,

            /*      Set Section: Information Grid          */
            portfolioName: this.newWebsite.portfolioName,

            /*      Set Section: Information Grid          */
            cooperationName: this.newWebsite.cooperationName,

            /*      Set Section: Identity          */
            pageTitle: this.newWebsite.name,

            /*      Set Section: Banner               */
            bannerTitle: this.newWebsite.bannerTitle,
            bannerDescription: this.newWebsite.bannerDesc,

            /*      Set Section: About                */
            companyTitle: this.newWebsite.aboutTitle,
            companyDescription: this.newWebsite.aboutDesc,
            companyOptions: this.newWebsite.aboutOptions,

            /*      Set Section: Products             */
            productTitle: this.newWebsite.productTitle,
            productDescription: this.newWebsite.productDesc,

            /*      Set Section: QAndA                */
            faqTitle: this.newWebsite.QAndATitle,
            faqDescription: this.newWebsite.QAndADesc,
            questionsAndAnswers: this.newWebsite.QAndAOptions,

            /*      Set Section: Video                */
            videoTitle: this.newWebsite.videoTitle,
            videoDescription: this.newWebsite.videoDesc,
            videoLink: this.newWebsite.videoLink ? this.newWebsite.videoLink.replaceAll('youtube.com', 'youtube-nocookie.com')
              .replaceAll('watch?v=', 'embed/').replaceAll('short', 'embed') : '',

            /*      Set Section: Customers Opinion    */
            opinionTitle: this.newWebsite.opinionTitle,
            opinionDescription: this.newWebsite.opinionDesc,

            /*      Set Section: Contact          */
            contactTitle: this.newWebsite.contactTitle,
            contactDescription: this.newWebsite.contactDesc,
            contactDetails: this.newWebsite.contactDetails,
          },
        ],

        informationGridEnabled: this.newWebsite.informationGridEnabled || false,
        informationGridAsBoxes: this.newWebsite.showInformationGridBox || false,
        offerWebTemplateInformationGridItems: this.newWebsite.informationGridOptions || [],
        offerWebTemplateFooterItems: this.newWebsite.offerWebTemplateFooterItems || [],
      }

      if (this.newWebsite.id && this.newWebsite.id.length > 0) newWebsite.id = this.newWebsite.id

      if (this.newOption?.author && this.newOption?.description && this.newOption?.title) {
        newWebsite.offerWebTemplateOpinionItems.push({
          avatar: this.newOption.avatar,
          translations: [
            {
              locale: 'pl_pl',
              title: this.newOption.title,
              description: this.newOption.description,
              author: this.newOption.author,
            },
          ],
        })
      }
        // this.newWebsite.informationGridOptions = web.
      return newWebsite
    },

    async pushWebsite() {
      const action = this.newWebsite.id ? UPDATE_WEBSITE : CREATE_WEBSITE
      const newWebsite = this.prepareData()

      const listOfferWebTemplatePortfolioItems = []

      await Promise.all(newWebsite.offerWebTemplatePortfolioItems.map(async (product, i) => {
        let newFile = ''

        if (product.file) {
          const { file, token } = product?.file

          if (token) {
            newFile = token
          }

          if (file?.type) {
            const {files} = await this.$store.dispatch(`uploader/${UPLOAD_FILES}`, {
              uploadedFiles: [file],
              type: 'debit',
            })

            newFile = files[0]
          }

          listOfferWebTemplatePortfolioItems.push({
            file: newFile,
            translations: product.translations
          })

          return
        }

        listOfferWebTemplatePortfolioItems.push({
          translations: product.translations
        })
      })).then(() => { newWebsite.offerWebTemplatePortfolioItems = listOfferWebTemplatePortfolioItems })

      const listCooperationFiles = []

      await Promise.all(newWebsite.cooperationFiles.map(async (fileItem, i) => {
        const { file, token } = fileItem?.file

        if (token) {
          listCooperationFiles.push(token)
          return
        }

        if (file?.type) {
          const { files } = await this.$store.dispatch(`uploader/${UPLOAD_FILES}`, {
            uploadedFiles: [file],
            type: 'debit',
          })

          listCooperationFiles.push(files[0])
        }
      })).then(() => { newWebsite.cooperationFiles = listCooperationFiles })

      /*      Push: Identity - Logo      */
      if (!!newWebsite.logo && !newWebsite.logo.token) {
        await this.$store.dispatch(`uploader/${UPLOAD_FILES}`, {
          uploadedFiles: [newWebsite.logo],
          type: 'debit',
        })
          .then(res => {
            const [first] = res.files
            newWebsite.logo = first
          })
          .catch(err => {
            console.error(err)
          })
      } else if (!!newWebsite.logo && newWebsite.logo.token) newWebsite.logo = newWebsite.logo.token

      /*      Push: Identity - Favicon      */
      if (!!newWebsite.favicon && !newWebsite.favicon.token) {
        await this.$store.dispatch(`uploader/${UPLOAD_FILES}`, {
          uploadedFiles: [newWebsite.favicon],
          type: 'debit',
        })
          .then(res => {
            const [first] = res.files

            newWebsite.favicon = first
          })
          .catch(err => {
            console.error(err)
          })
      } else if (!!newWebsite.favicon && newWebsite.favicon.token) newWebsite.favicon = newWebsite.favicon.token

      /*      Push: bannerBackground - IMG      */
      if (!!newWebsite.bannerBackground && !newWebsite.bannerBackground.token) {
        await this.$store.dispatch(`uploader/${UPLOAD_FILES}`, {
          uploadedFiles: [newWebsite.bannerBackground.file],
          type: 'debit',
        })
          .then(res => {
            const [first] = res.files
            newWebsite.bannerBackground = first
          })
          .catch(err => {
            console.error(err)
          })
      } else if (!!newWebsite.bannerBackground && newWebsite.bannerBackground.token) newWebsite.bannerBackground = newWebsite.bannerBackground.token

      /*      Push: Banner - IMG      */
      if (!!newWebsite.banner && !newWebsite.banner.token) {
        await this.$store.dispatch(`uploader/${UPLOAD_FILES}`, {
          uploadedFiles: [newWebsite.banner.file],
          type: 'debit',
        })
          .then(res => {
            const [first] = res.files
            newWebsite.banner = first
          })
          .catch(err => {
            console.error(err)
          })
      } else if (!!newWebsite.banner && newWebsite.banner.token) newWebsite.banner = newWebsite.banner.token

      /*      Push: About Banner - IMG      */
      if (!!newWebsite.companyBanner && !newWebsite.companyBanner.token) {
        await this.$store.dispatch(`uploader/${UPLOAD_FILES}`, {
          uploadedFiles: [newWebsite.companyBanner],
          type: 'debit',
        })
          .then(res => {
            const [first] = res.files

            newWebsite.companyBanner = first
          })
          .catch(err => {
            console.error(err)
          })
      } else if (!!newWebsite.companyBanner && newWebsite.companyBanner.token) newWebsite.companyBanner = newWebsite.companyBanner.token

      /*      Push: Contact - IMG      */
      if (!!newWebsite.contactAvatar && !newWebsite.contactAvatar.token) {
        await this.$store.dispatch(`uploader/${UPLOAD_FILES}`, {
          uploadedFiles: [newWebsite.contactAvatar],
          type: 'debit',
        })
          .then(res => {
            const [first] = res.files
            newWebsite.contactAvatar = first
          })
          .catch(err => {
            console.error(err)
          })
      } else if (!!newWebsite.contactAvatar && newWebsite.contactAvatar.token) newWebsite.contactAvatar = newWebsite.contactAvatar.token

      /*      Push: Opinion - Avatars      */
      if (
          newWebsite?.offerWebTemplateOpinionItems
          && newWebsite?.offerWebTemplateOpinionItems.length > 0
      ) {
        const opinionsAvatars = []

        //  Pulling the avatars to push
        newWebsite.offerWebTemplateOpinionItems.forEach(opi => {
          if (
            !!opi.translations
              && opi?.translations.length > 0
              && opi?.avatar
              && typeof opi?.avatar === 'object'
              && !opi?.avatar.token
          ) {
            opinionsAvatars.push(opi.avatar)
          }
        })

        if (opinionsAvatars.length > 0) {
          await this.$store.dispatch(`uploader/${UPLOAD_FILES}`, {
            uploadedFiles: opinionsAvatars,
            type: 'debit',
          })
            .then(res => {
              let ioa = 0

              newWebsite.offerWebTemplateOpinionItems.forEach((opi, index) => {
                if (opi.avatar && typeof opi.avatar === 'object') {
                  // eslint-disable-next-line no-plusplus,no-nested-ternary
                  newWebsite.offerWebTemplateOpinionItems[index].avatar = !opi.avatar.token ? res.files[ioa++] : opi.avatar.token
                } else {
                  newWebsite.offerWebTemplateOpinionItems[index].avatar = null
                }
              })
            })
            .catch(err => {
              console.error(err)
            })
        }

        newWebsite.offerWebTemplateOpinionItems.forEach((opi, index) => {
          newWebsite.offerWebTemplateOpinionItems[index].avatar = opi.avatar && opi.avatar.token ? opi.avatar.token : opi.avatar
        })

        newWebsite.offerWebTemplateOpinionItems.forEach((opi, index) => {
          if ((typeof opi.avatar === 'string' && !opi.avatar) || (opi.avatar && typeof opi.avatar === 'object' && Object.keys(opi.avatar).length === 0)) {
            newWebsite.offerWebTemplateOpinionItems[index].avatar = null
          }

          if (opi.createdAt) {
            delete newWebsite.offerWebTemplateOpinionItems[index].createdAt
            delete newWebsite.offerWebTemplateOpinionItems[index].createdBy
            delete newWebsite.offerWebTemplateOpinionItems[index].deletedAt
            delete newWebsite.offerWebTemplateOpinionItems[index].deletedBy
            delete newWebsite.offerWebTemplateOpinionItems[index].updatedAt
            delete newWebsite.offerWebTemplateOpinionItems[index].updatedBy
            delete newWebsite.offerWebTemplateOpinionItems[index].offerWebTemplate
          }

          newWebsite.offerWebTemplateOpinionItems[index].translations.forEach((lang, langIndex) => {
            if (lang.createdAt) {
              delete newWebsite.offerWebTemplateOpinionItems[index].translations[langIndex].createdAt
              delete newWebsite.offerWebTemplateOpinionItems[index].translations[langIndex].createdBy
              delete newWebsite.offerWebTemplateOpinionItems[index].translations[langIndex].deletedAt
              delete newWebsite.offerWebTemplateOpinionItems[index].translations[langIndex].deletedBy
              delete newWebsite.offerWebTemplateOpinionItems[index].translations[langIndex].updatedAt
              delete newWebsite.offerWebTemplateOpinionItems[index].translations[langIndex].updatedBy
              delete newWebsite.offerWebTemplateOpinionItems[index].translations[langIndex].offerWebTemplate
            }
          })
        })
      }

      const sectionsOrder = this.sectionList.map(({ key }) => key)

        // console.log(newWebsite)
        // return

      this.$store.dispatch(`websites/${action}`, { ...newWebsite, sectionsOrder })
        .then(() => {
          const text = action === CREATE_CONTRACT ? this.$i18n.t('success.offerWebCreated') : this.$i18n.t('success.offerWebUpdated')
          this.showToast('success', text, this.$i18n.t('success.saved'))

          setTimeout(() => {
            this.$router.push({ name: 'offr/listWebsite' })
          }, 1000)
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
  },
}
</script>

<style>
.basicOfferInfoLoading {
  min-height: 70vh;
}
</style>
